import "./css/reset.css"
import "./css/colors.css"
import "./css/layout.css"
import "./css/plugins.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/hero.css"
import "./css/details.css"
import "./css/masonry.css"

import "./css/engagement.css"
import "./css/arguments.css"
import "./css/faq.css"
import "./css/articles.css"
import "./css/testimonials.css"
import "./css/donate.css"
import "./css/cards.css"
import "./css/shop.css"
import "./css/cookiecontrol.css"
// import "./css/leadmagnet.css"
import "./css/gallery.css"
import "./css/pledge.css"
import "./css/committees.css"
import "./css/supporters.css"
import "./css/profileImageGenerator.css"

import "./css/admin.css"
import "./css/variables.css"

import { onReady, qs, qsa } from "./js/utils/dom"
import { initNav } from "./js/components/nav"
import { initTestimonials } from "./js/components/testimonials"
import { initMasonry } from "./js/lib/masonry"
import { initFAQ } from "./js/components/faq"
import { initNewsletterForm } from "./js/components/newsletterForm"
// import { initLeadMagnet } from "./js/components/leadmagnet"
import { initPledgeProgressBar } from "./js/components/pledge"
import { initPlausibleForLanguageSites } from "./js/utils/plausible"
// import { initPayrexxFrame } from "./js/components/payrexx"
import { initSupportingMembersForm } from "./js/components/committees"
import { initProfileImageGenerator } from "./js/components/profileImageGenerator"

onReady(initNav)
onReady(initFAQ)
onReady(initTestimonials)
onReady(initMasonry)
onReady(initNewsletterForm)
// onReady(initLeadMagnet)
onReady(initPledgeProgressBar)
onReady(initPlausibleForLanguageSites)
// initPayrexxFrame()
onReady(initSupportingMembersForm)
onReady(initProfileImageGenerator)

onReady(async () => {
  const galleries = qsa(".swiper")
  if (galleries.length) {
    const { renderGallery } = await import(
      /* webpackPrefetch: true */ "./js/components/gallery"
    )
    galleries.forEach(renderGallery)
  }
})

onReady(async () => {
  const form = qs("#testimonial-create")

  if (form) {
    const { initTestimonialForm } = await import(
      "./js/components/testimonialImageGenerator"
    )
    initTestimonialForm(form)
  }
})

onReady(async () => {
  const signatureForm = qs("#signature-form")
  if (signatureForm) {
    const { initSignatureForm } = await import("./js/components/signatures")
    initSignatureForm()
  }
})

const cl = document.documentElement.classList
cl.remove("no-js")
cl.add("js")
