import { setDetailsHeights } from "../utils/details"
import { qsa } from "../utils/dom"

export const initFAQ = () => {
  setDetailsHeights(".faq__details")

  document.body.addEventListener("click", (e) => {
    const details = e.target.closest(".faq__details")
    for (let el of qsa(".faq__details[open]")) {
      if (el !== details) {
        el.removeAttribute("open")
      }
    }
  })
}
