import { qs, qsa } from "../utils/dom"

const setCellSizes = () => {
  for (let parent of qsa(".masonry")) {
    const style = getComputedStyle(parent)
    const gap = parseInt(style.gridRowGap, 10)
    const height = parseInt(style.gridAutoRows, 10) + gap

    for (let cell of parent.children) {
      if (cell.matches(".masonry__cell")) {
        let cellHeight = gap
        for (let child of cell.children) {
          cellHeight += child.getBoundingClientRect().height
        }
        const span = Math.ceil(cellHeight / height)
        cell.style.gridRowEnd = `span ${span}`
      }
    }
  }
}

export function initMasonry() {
  if (CSS.supports("grid-template-rows", "masonry")) {
    // Nice, we're done
    return
  }

  setCellSizes()
  window.addEventListener("load", setCellSizes)

  setTimeout(() => {
    setCellSizes()
  }, 750) //i know, its ugly, but seems the only way.. masonry is now supported in latest FF

  const showMore = qs(".testimonial__show-more")
  if (showMore) {
    showMore.addEventListener("click", () => {
      setTimeout(() => {
        setCellSizes()
      }, 60)
    })
  }

  window.addEventListener("resize", setCellSizes)
}
