/* global gettext, interpolate */

import { createContext, render } from "preact"
import { useContext, useMemo, useState } from "preact/hooks"
import { qs, qsa } from "../utils/dom"

const TestimonialContext = createContext()
const useTestimonialContext = () => useContext(TestimonialContext)

const DEFAULTS = {
  search: true,
  paginateBy: 12,
  listBase: "",
  support: "%(name)s",
}

export const initTestimonials = () => {
  let data
  for (let testimonialsEl of qsa("[data-testimonials]")) {
    if ((data = qs("script", testimonialsEl))) {
      render(
        <TestimonialContext.Provider
          value={{ ...DEFAULTS, ...testimonialsEl.dataset }}
        >
          <TestimonialsApp data={JSON.parse(data.textContent || {})} />
        </TestimonialContext.Provider>,
        testimonialsEl,
      )
    }
  }
}

// Thanks, https://stackoverflow.com/a/37511463
const unaccent = (s) => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

const filterTestimonials = (data, filterWord) => {
  const term = unaccent(filterWord).toLowerCase()
  return data.filter((obj) => obj.searchString.includes(term))
}

const TestimonialsApp = ({ data }) => {
  const testimonials = useMemo(
    () =>
      data.map((t) => ({ ...t, searchString: unaccent(t.name).toLowerCase() })),
    [data],
  )
  //console.log({ testimonials })
  const { paginateBy, search } = useTestimonialContext()
  const [slices, setSlices] = useState(1)
  const [filterWord, setFilterWord] = useState("")

  const filteredTestimonials = filterTestimonials(testimonials, filterWord)
  const shownTestimonials = filteredTestimonials.slice(
    (slices - 1) * paginateBy,
    slices * paginateBy,
  )

  const showNext = slices * paginateBy < filteredTestimonials.length
  const showPrevious = (slices - 1) * paginateBy > 0

  return (
    <>
      {search ? (
        <div class="form plugin plugin--testimonials-search">
          <span class="testimonials__search">
            <input
              class="input__search"
              type="text"
              placeholder={gettext("suchen")}
              value={filterWord}
              onInput={(e) => setFilterWord(e.target.value)}
            />
          </span>
        </div>
      ) : null}
      {shownTestimonials.length < 1 ? (
        <div class="">{gettext("Keine Botschaften gefunden.")}</div>
      ) : (
        <div class="testimonials-grid masonry">
          {shownTestimonials.map((testimonial) => (
            <TestimonialBox key={testimonial.id} data={testimonial} />
          ))}
        </div>
      )}
      {showNext || showPrevious ? (
        <div class="testimonial__show-more">
          {showPrevious ? (
            <button
              type="button"
              class="button button--more"
              onClick={() => {
                setSlices(slices - 1)
              }}
            >
              {gettext("previous page")}
            </button>
          ) : null}
          {showNext ? (
            <button
              type="button"
              class="button button--more"
              onClick={() => {
                setSlices(slices + 1)
              }}
            >
              {gettext("next page")}
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

const TestimonialBox = ({ data }) => {
  const { listBase, support } = useTestimonialContext()
  const sharingText = `${encodeURI(interpolate(support, data, true))} \n`
  const sharingURL = `${listBase}${data.id}/`

  return (
    <div class="testimonial masonry__cell">
      <img
        class="testimonial__image"
        src={data.portrait}
        alt={`${gettext("Portrait of")} ${data.name}`}
      />
      <div class="testimonial__box accented">
        {data.statement ? (
          <blockquote class="testimonial__statement">
            «{data.statement}»
          </blockquote>
        ) : null}
        {data.statement ? <div class="testimonial__separator" /> : null}
        <p class="testimonial__name">{data.name}</p>
        <p class="testimonial__profession">{data.profession}</p>
        <div class="testimonial__sharing">
          <ShareLink
            title={gettext("Share on Facebook")}
            url={`https://www.facebook.com/sharer.php?u=${sharingURL}?landscape`}
          >
            <svg class="icon">
              <use xlinkHref="#icon-facebook" />
            </svg>
          </ShareLink>
          <ShareLink
            title={gettext("Share on Twitter")}
            url={`https://twitter.com/share?url=${sharingURL}`}
          >
            <svg class="icon">
              <use xlinkHref="#icon-twitter" />
            </svg>
          </ShareLink>
          <ShareLink
            title={gettext("Share on Whatsapp")}
            url={`https://api.whatsapp.com/send?phone=&text=${sharingText}${sharingURL}`}
          >
            <svg class="icon">
              <use xlinkHref="#icon-whatsapp" />
            </svg>
          </ShareLink>
          <ShareLink
            title={gettext("Share on Telegram")}
            url={`https://t.me/share/url?url=${sharingURL}&text=${sharingText}`}
          >
            <svg class="icon">
              <use xlinkHref="#icon-telegram" />
            </svg>
          </ShareLink>
          <DownloadLink
            src={data.sharing_image}
            title={gettext("Share on Instagram")}
          >
            <svg class="icon">
              <use xlinkHref="#icon-instagram" />
            </svg>
          </DownloadLink>
          <DownloadLink
            src={data.sharing_image}
            title={gettext("Download testimonial")}
          >
            <svg class="icon">
              <use xlinkHref="#icon-download" />
            </svg>
          </DownloadLink>
        </div>
      </div>
    </div>
  )
}

const ShareLink = ({ title, url, children }) => {
  return (
    <a
      class="some-link"
      href={url}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span class="sr-only">{title}</span>
      {children}
    </a>
  )
}

const DownloadLink = ({ title, src, children }) => {
  return (
    <a class="some-link" href={src} title={title} download="testimonial.png">
      <span class="sr-only">{title}</span>
      {children}
    </a>
  )
}
